import { createContext, useContext, useState } from 'react'

const MobileSearchContext = createContext()

export const MobileSearchProvider = ({ children }) => {
  const [mobileSearch, setMobileSearch] = useState({
    requestQuery: false,
    request: false,
    loading: false,
    data: {},
    categories: [],
    suggest: null,
    merchandising: null,
  })

  const value = {
    mobileSearch,
    setMobileSearch,
  }

  return <MobileSearchContext.Provider value={value}>{children}</MobileSearchContext.Provider>
}

export const useMobileSearchContext = () => useContext(MobileSearchContext)
